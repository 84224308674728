@import "/node_modules/antd/dist/antd.less";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// -------- Colors -----------
@primary-color: #000000;
@text-color: @primary-color;
@text-color-secondary: #18067D;
@text-color-gray: #8C8C8C;
@heading-color: @primary-color;

// Base
@border-radius-base: 4px;

// Buttons
@btn-primary-bg: #000000;
@btn-ghost-bg: #ffffff;
@btn-default-bg: @text-color-secondary;
@btn-border-radius-base: 10px;

@btn-primary-color: #ffffff;
@btn-default-color: #ffffff;
@btn-ghost-color: #000000;
// dark theme
@menu-dark-bg: @primary-color;

// Form
@label-color: @text-color-secondary;
@input-color: @primary-color;

// LINK
@link-color: @primary-color;

// Border color
@border-color-base: #d9dce2;

// Select
@select-item-selected-color: @primary-color;

// Layout
@layout-body-background: #FAFAFA;
@layout-header-background: #ffffff;
@layout-header-height: 78px; // does not work
@layout-header-padding: 0 24px; // does not work
@layout-sider-background: @primary-color;

// Disabled states
@disabled-bg: #f4f6fa;

// Table
@table-header-bg: #ffffff;

// Modal
@modal-border-radius: 20px;